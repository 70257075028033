import React, { createContext, useContext, useEffect, useRef, useState } from "react";

const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const videoElementRef = useRef(null);
  const videoDealDetailRef = useRef(null);
  const preloadedVideos = useRef({});
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [isVideoEnded2, setIsVideoEnded2] = useState(false);
  const [isVideoEnded3, setIsVideoEnded3] = useState(false);
  const [isVideoEndedEditDeal, setisVideoEndedEditDeal] = useState(false);
  const [isVideoEndedreferVideoSrc, setisVideoEndedreferVideoSrc] = useState(false);
  const [isVideoEndedinfinityVideoSrc, setisVideoEndedinfinityVideoSrc] = useState(false);
  const [isVideoEndedplayGameVideoSrc, setisVideoEndedplayGameVideoSrc] = useState(false);
  const [isVideoEndedwelcomToMenu, setisVideoEndedwelcomToMenu] = useState(false);
  const [isVideoEndedvideoElement, setisVideoEndedvideoElement] = useState(false);
  const [isVideoEndedcongratsVideoSrc, setisVideoEndedcongratsVideoSrc] = useState(false);
  const [isVideoEndedDash, setisVideoEndedDash] = useState(false);

  // const prefetch = async (urls) => {
  //   if (typeof urls === "string") {
  //     urls = [urls];
  //   }

  //   try {
  //     const fetchPromises = urls.map(
  //       (url) => fetch(url, { method: "GET", cache: "force-cache" }) // Force cache to store the response
  //     );

  //     const responses = await Promise.all(fetchPromises);

  //     // Check if all responses are OK
  //     const isSuccess = responses.every((response) => response.ok);

  //     return isSuccess;
  //   } catch (error) {
  //     console.error("Video prefetch failed:", error);
  //     return false;
  //   }
  // };

  useEffect(() => {
    //   prefetch("../assets/Videos/WelcomVideo.webm").then((isSuccess) => {
    //     if (isSuccess) {
    //       console.log("Video prefetched successfully.");
    //     } else {
    //       console.log("Video prefetch failed.");
    //     }
    //   });

    //   prefetch("../assets/Videos/PageVideos/clientdealdetailpage.webm").then(
    //     (isSuccess) => {
    //       if (isSuccess) {
    //         console.log("Video prefetched successfully.");
    //       } else {
    //         console.log("Video prefetch failed.");
    //       }
    //     }
    //   );

    //   prefetch("../assets/Videos/PageVideos/readDisclaimer.webm").then(
    //     (isSuccess) => {
    //       if (isSuccess) {
    //         console.log("Video prefetched successfully.");
    //       } else {
    //         console.log("Video prefetch failed.");
    //       }
    //     }
    //   );

    //   prefetch("../assets/Videos/PageVideos/editDealInforamatiom.webm").then(
    //     (isSuccess) => {
    //       if (isSuccess) {
    //         console.log("Video prefetched successfully.");
    //       } else {
    //         console.log("Video prefetch failed.");
    //       }
    //     }
    //   );
    // Preload the video and store it in the ref object
    const video1 = document.createElement("video");
    video1.src = "../assets/Videos/WelcomVideo.webm";
    video1.preload = "auto";
    video1.setAttribute("playsinline", "true");
    video1.setAttribute("webkit-playsinline", "true");

    video1.classList.add("w-100");
    video1.classList.add("Double_Videos");
    preloadedVideos.current["video1"] = video1;
    video1.onended = () => {
      setIsVideoEnded(true);
    };
    video1.onplay = () => {
      setIsVideoEnded(false);
    };

    /*-------------------------*/
    /*-------------------------*/

    const dealDetailVideoSrc = document.createElement("video");
    dealDetailVideoSrc.src = "../assets/Videos/PageVideos/clientdealdetailpage.webm";
    dealDetailVideoSrc.preload = "auto";
    dealDetailVideoSrc.setAttribute("playsinline", "true");
    dealDetailVideoSrc.setAttribute("webkit-playsinline", "true");
    dealDetailVideoSrc.muted = true;
    preloadedVideos.current["dealDetailVideoSrc"] = dealDetailVideoSrc;
    dealDetailVideoSrc.onended = () => {
      setIsVideoEnded2(true);
    };
    dealDetailVideoSrc.onplay = () => {
      setIsVideoEnded2(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const readDisclaimerVideoSrc = document.createElement("video");
    readDisclaimerVideoSrc.src = "../assets/Videos/PageVideos/readDisclaimer.webm";
    readDisclaimerVideoSrc.preload = "auto";
    readDisclaimerVideoSrc.setAttribute("playsinline", "true");
    readDisclaimerVideoSrc.setAttribute("webkit-playsinline", "true");
    preloadedVideos.current["readDisclaimerVideoSrc"] = readDisclaimerVideoSrc;
    readDisclaimerVideoSrc.onended = () => {
      setIsVideoEnded3(true);
    };
    readDisclaimerVideoSrc.onplay = () => {
      setIsVideoEnded3(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const dealEditVideoSrc = document.createElement("video");
    dealEditVideoSrc.src = "../assets/Videos/PageVideos/editDealInforamatiom.webm";
    dealEditVideoSrc.preload = "auto";
    preloadedVideos.current["dealEditVideoSrc"] = dealEditVideoSrc;
    dealEditVideoSrc.onended = () => {
      setisVideoEndedEditDeal(true);
    };
    dealEditVideoSrc.onplay = () => {
      setisVideoEndedEditDeal(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const playGameVideoSrc = document.createElement("video");
    playGameVideoSrc.src = "../assets/Videos/letsPlayGameVideo.webm";
    playGameVideoSrc.preload = "auto";
    preloadedVideos.current["playGameVideoSrc"] = playGameVideoSrc;
    playGameVideoSrc.onended = () => {
      setisVideoEndedplayGameVideoSrc(true);
    };
    playGameVideoSrc.onplay = () => {
      setisVideoEndedplayGameVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const CongratulationsDiscount = document.createElement("video");
    CongratulationsDiscount.src = "../assets/Videos/GameCongratsVideos/Congratulations-discount.webm";
    CongratulationsDiscount.preload = "auto";
    preloadedVideos.current["CongratulationsDiscount"] = CongratulationsDiscount;
    CongratulationsDiscount.classList.add("GameCongratsVideo");
    CongratulationsDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    CongratulationsDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const WoohooDiscount = document.createElement("video");
    WoohooDiscount.src = "../assets/Videos/GameCongratsVideos/Woohoo-discount.webm";
    WoohooDiscount.preload = "auto";
    WoohooDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["WoohooDiscount"] = WoohooDiscount;
    WoohooDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    WoohooDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const YayDiscount = document.createElement("video");
    YayDiscount.src = "../assets/Videos/GameCongratsVideos/Yay-discount.webm";
    YayDiscount.preload = "auto";
    YayDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["YayDiscount"] = YayDiscount;
    YayDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    YayDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const BravoDiscount = document.createElement("video");
    BravoDiscount.src = "../assets/Videos/GameCongratsVideos/Bravo-discount.webm";
    BravoDiscount.preload = "auto";
    BravoDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["BravoDiscount"] = BravoDiscount;
    BravoDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    BravoDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const CheersDiscount = document.createElement("video");
    CheersDiscount.src = "../assets/Videos/GameCongratsVideos/Cheers-discount.webm";
    CheersDiscount.preload = "auto";
    CheersDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["CheersDiscount"] = CheersDiscount;
    CheersDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    CheersDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const FantasticDiscount = document.createElement("video");
    FantasticDiscount.src = "../assets/Videos/GameCongratsVideos/Fantastic-discount.webm";
    FantasticDiscount.preload = "auto";
    FantasticDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["FantasticDiscount"] = FantasticDiscount;
    FantasticDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    FantasticDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const GreatJobDiscount = document.createElement("video");
    GreatJobDiscount.src = "../assets/Videos/GameCongratsVideos/GreatJob-discount.webm";
    GreatJobDiscount.preload = "auto";
    GreatJobDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["GreatJobDiscount"] = GreatJobDiscount;
    GreatJobDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    GreatJobDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const HoorayDiscount = document.createElement("video");
    HoorayDiscount.src = "../assets/Videos/GameCongratsVideos/Hooray-discount.webm";
    HoorayDiscount.preload = "auto";
    HoorayDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["HoorayDiscount"] = HoorayDiscount;
    HoorayDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    HoorayDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const AwesomeDiscount = document.createElement("video");
    AwesomeDiscount.src = "../assets/Videos/GameCongratsVideos/Awesome-discount.webm";
    AwesomeDiscount.preload = "auto";
    AwesomeDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["AwesomeDiscount"] = AwesomeDiscount;
    AwesomeDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    AwesomeDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const WayDiscount = document.createElement("video");
    WayDiscount.src = "../assets/Videos/GameCongratsVideos/Way-discount.webm";
    WayDiscount.preload = "auto";
    WayDiscount.classList.add("GameCongratsVideo");
    preloadedVideos.current["WayDiscount"] = WayDiscount;
    WayDiscount.onended = () => {
      setisVideoEndedcongratsVideoSrc(true);
    };
    WayDiscount.onplay = () => {
      setisVideoEndedcongratsVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const referVideoSrc = document.createElement("video");
    referVideoSrc.src = "../assets/Videos/referSomeone.webm";
    referVideoSrc.preload = "auto";
    preloadedVideos.current["referVideoSrc"] = referVideoSrc;
    referVideoSrc.onended = () => {
      setisVideoEndedreferVideoSrc(true);
    };
    referVideoSrc.onplay = () => {
      setisVideoEndedreferVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const infinityVideoSrc = document.createElement("video");
    infinityVideoSrc.src = "../assets/Videos/PageVideos/infinityPage.webm";
    infinityVideoSrc.preload = "auto";
    preloadedVideos.current["infinityVideoSrc"] = infinityVideoSrc;
    infinityVideoSrc.onended = () => {
      setisVideoEndedinfinityVideoSrc(true);
    };
    infinityVideoSrc.onplay = () => {
      setisVideoEndedinfinityVideoSrc(false);
    };
    /*-------------------------*/
    /*-------------------------*/

    const welcomToMenu = document.createElement("video");
    welcomToMenu.src = "../assets/Videos/PageVideos/welcomeToMenu.webm";
    welcomToMenu.preload = "auto";
    welcomToMenu.classList.add("w-100");
    welcomToMenu.classList.add("Double_Videos");
    preloadedVideos.current["welcomToMenu"] = welcomToMenu;
    welcomToMenu.onended = () => {
      setisVideoEndedwelcomToMenu(true);
    };
    welcomToMenu.onplay = () => {
      setisVideoEndedwelcomToMenu(false);
    };

    /*-------------------------*/
    /*-------------------------*/

    const congratsVideoSrc = document.createElement("video");
    congratsVideoSrc.src = "../assets/Videos/CongratulationsModalVideo.webm";
    congratsVideoSrc.preload = "auto";
    preloadedVideos.current["congratsVideoSrc"] = congratsVideoSrc;

    /*-------------------------*/
    /*-------------------------*/

    /*-------------------------*/
  }, []);

  const playPreloadedVideo = (videoKey, videoRef) => {
    // console.log("videoKey", videoKey);
    // console.log("videoRef.current", videoRef.current);
    // console.log(
    //   "preloadedVideos.current[videoKey]",
    //   preloadedVideos.current[videoKey]
    // );

    if (videoRef.current && preloadedVideos.current[videoKey]) {
      const originalVideoElement = preloadedVideos.current[videoKey];
      const parentElement = videoRef.current.parentNode;

      if (parentElement) {
        // Hide the video initially
        originalVideoElement.style.display = "none";
        originalVideoElement.muted = true; // Keep it muted to avoid autoplay restrictions

        // Replace the current video with the preloaded one
        parentElement.replaceChild(originalVideoElement, videoRef.current);
        videoRef.current = originalVideoElement;

        // Event listener for when the video time progresses past the first frame
        const handleTimeUpdate = () => {
          if (videoRef.current?.currentTime >= 0.02) {
            // Wait until it has played beyond the first frame
            videoRef.current.currentTime = 0;
            videoRef.current.style.display = "block"; // Show the video after it has played a little
            videoRef.current.muted = false; // Unmute the video
            videoRef.current.removeEventListener("timeupdate", handleTimeUpdate); // Clean up the event listener
          }
        };

        // Add the "timeupdate" event listener
        videoRef.current.addEventListener("timeupdate", handleTimeUpdate);

        // videoRef.current.style.display = "block"; // Show the video after it has played a little
        // videoRef.current.muted = false;

        // Play the video
        videoRef.current.currentTime = 0; // Start from the beginning

        // if (videoKey == "welcomToMenu") {
        //   setTimeout(() => {
        //     videoRef.current.play().catch((error) => {
        //       console.error("Error playing video:", error);
        //     });
        //   }, 1000);
        // } else {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        // }
      }
    }
  };

  const playPreloadedQuestionVideos = (url, questionId) => {
    if (!preloadedVideos.current[questionId]) {
      const videoElement = document.createElement("video");
      // const sourceMP4 = document.createElement("source");
      videoElement.src = url;
      // sourceMP4.type = "video/webm";
      videoElement.preload = "auto";
      videoElement.muted = true;
      videoElement.setAttribute("playsinline", "true");
      videoElement.setAttribute("webkit-playsinline", "true");
      preloadedVideos.current[questionId] = videoElement;
      videoElement.onended = () => {
        setisVideoEndedvideoElement(true);
      };
      videoElement.onplay = () => {
        setisVideoEndedvideoElement(false);
      };
    }
  };

  const playPreloadedThreeVideos = (url1, url2, url3, questionId1, questionId2, questionId3) => {
    if (url1) {
      // console.log("url1", url1);

      const videoElement = document.createElement("video");

      // const sourceMP4 = document.createElement("source");
      videoElement.src = url1;
      // sourceMP4.type = "video/webm";
      videoElement.preload = "auto";
      videoElement.muted = true;
      videoElement.setAttribute("playsinline", "true");
      videoElement.setAttribute("webkit-playsinline", "true");
      preloadedVideos.current[questionId1] = videoElement;
      videoElement.onended = () => {
        setisVideoEndedvideoElement(true);
      };
      videoElement.onplay = () => {
        setisVideoEndedvideoElement(false);
      };
    }

    if (url2) {
      // console.log("url2", url2);
      const videoElement = document.createElement("video");
      // const sourceMP4 = document.createElement("source");
      videoElement.src = url2;
      // sourceMP4.type = "video/webm";
      videoElement.preload = "auto";
      videoElement.muted = true;
      videoElement.setAttribute("playsinline", "true");
      videoElement.setAttribute("webkit-playsinline", "true");
      preloadedVideos.current[questionId2] = videoElement;
      videoElement.onended = () => {
        setisVideoEndedvideoElement(true);
      };
      videoElement.onplay = () => {
        setisVideoEndedvideoElement(false);
      };
    }

    if (url3) {
      // console.log("url3", url3);
      if (questionId3 == "welcomToMenu") {
        const videoElement = document.createElement("video");

        // const sourceMP4 = document.createElement("source");
        videoElement.src = url3;
        // sourceMP4.type = "video/webm";
        videoElement.preload = "auto";
        videoElement.muted = true;
        videoElement.classList.add("w-100");
        videoElement.classList.add("Double_Videos");
        // videoElement.appendChild(sourceMP4);

        preloadedVideos.current[questionId3] = videoElement;
        videoElement.onended = () => {
          setisVideoEndedDash(true);
        };
        videoElement.onplay = () => {
          setisVideoEndedDash(false);
        };
      } else {
        const videoElement = document.createElement("video");

        // const sourceMP4 = document.createElement("source");
        videoElement.src = url3;
        // sourceMP4.type = "video/webm";
        videoElement.preload = "auto";
        videoElement.muted = true;
        videoElement.classList.add("Bot_Buttons");
        videoElement.classList.add("Big-size-background-Ai-video");
        // videoElement.appendChild(sourceMP4);

        preloadedVideos.current[questionId3] = videoElement;
        videoElement.onended = () => {
          setisVideoEndedvideoElement(true);
        };
        videoElement.onplay = () => {
          setisVideoEndedvideoElement(false);
        };
      }
    }
  };

  return (
    <VideoContext.Provider
      value={{
        playPreloadedVideo,
        playPreloadedQuestionVideos,
        playPreloadedThreeVideos,
        isVideoEnded,
        isVideoEnded2,
        isVideoEnded3,
        isVideoEndedEditDeal,
        isVideoEndedreferVideoSrc,
        isVideoEndedinfinityVideoSrc,
        isVideoEndedplayGameVideoSrc,
        isVideoEndedwelcomToMenu,
        isVideoEndedvideoElement,
        isVideoEndedcongratsVideoSrc,
        isVideoEndedDash,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export const useVideo = () => useContext(VideoContext);
