import React from "react";
import { useAudio } from "../../AudioContext";
const MyButton = ({ type, title, onModalOpen, children, ...rest }) => {
  const { playClickSound } = useAudio();
  // const play = (audioPath) => {
  //   const audio = new Audio(audioPath);
  //   audio.play().catch((error) => {
  //     console.error("Audio playback failed:", error);
  //   });
  // };

  return (
    <button
      type={type}
      className="MyBtn"
      {...rest}
      onClick={() => {
        playClickSound();
        if (onModalOpen) {
          onModalOpen();
        }
      }}
    >
      {title}
      {children}
    </button>
  );
};

export default MyButton;
