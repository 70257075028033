import React, { useState, useEffect } from "react";
import Loader from "../Loader";

export default function LoadableImage({ src, alt, ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoading(false);
    img.onerror = () => setHasError(true);
  }, [src]);

  if (hasError) {
    return <div>Error loading image.</div>;
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && <img src={src} alt={alt} {...props} />}
    </>
  );
}
