import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ABCDealerImage from "../CommonFolder/CommonImages/ABCDealerImage";
import MenuVersaImage from "../CommonFolder/CommonImages/MenuVersaImage";
import SendCode from "./LoginInnerPages/SendCode";
import Login from "./LoginInnerPages/Login";
import VerifyCode from "./LoginInnerPages/VerifyCode";
import ChangePassword from "./LoginInnerPages/ChangePassword";
import "./LoginPages.css";
import Loader from "../CommonFolder/Loader";

const LoginLayout = ({ isLogin }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isGifLoaded, setGifLoaded] = useState(false);
  const [isAudioLoaded, setAudioLoaded] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);
  const login_section = useRef(null);
  const initial_audio = useRef(null);

  useEffect(() => {
    if (isGifLoaded && isAudioLoaded) {
      setIsLoading(false);
    }
  }, [isGifLoaded, isAudioLoaded]);

  const handleGifLoaded = () => {
    // alert("gif loaded");
    setGifLoaded(true);
  };

  const handleProceed = () => {
    setLoadLogin(true);

    // handleAudioLoaded();
  };

  const handleAudioLoaded = () => {
    // alert("audio loaded");
    const audio = initial_audio.current;
    audio.muted = true;
    // initial_audio.current.play();
    // alert("audio muted");
    audio
      .play()
      .then(() => {
        // Unmute after a delay
        // setTimeout(() => {
        audio.muted = false;
        // alert("unmute audio");
        // }, 1000); // Unmute after 1 second
      })
      .catch((error) => {
        console.error("Audio play failed:", error);
      });
    setAudioLoaded(true);
  };

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLogin ? (
        <Navigate to="/" />
      ) : (
        <section id="login_Sectoin">
          <div className={`container ${loadLogin ? "" : "loadLoginSection"}`}>
            {loadLogin ? (
              <div className="row">
                <div className="col-lg-7 Powered_Container">
                  {isLoading && <Loader />}
                  <div className="background-video rounded-0" style={{ display: isLoading ? "none" : "block" }}>
                    <video src="../assets/Videos/menuverse-gif.webm" onCanPlayThrough={handleVideoLoaded} style={{ display: isLoading ? "none" : "block" }} autoPlay loop playsInline webkit-playsinline></video>
                  </div>
                </div>
                <div className="col-lg-5 Form_Container">
                  <ABCDealerImage />
                  <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="sendCode" element={<SendCode />} />
                    <Route path="verifyCode" element={<VerifyCode />} />
                    <Route path="changePassword" element={<ChangePassword />} />
                    <Route path="*" element={<Navigate to="login" />} />
                  </Routes>
                  <MenuVersaImage />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-7 "></div>
                <div className="col-lg-5 ">
                  <button className="proceedToLogin" onClick={handleProceed}>
                    Proceed
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default LoginLayout;
