import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AudioProvider } from "./AudioContext";
import { VideoProvider } from "./VideoContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AudioProvider>
      <VideoProvider>
        <App />
      </VideoProvider>
    </AudioProvider>
  </BrowserRouter>
);

// serviceWorkerRegistration.register();
reportWebVitals();
