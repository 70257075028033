import React from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import MyButton from "../CommonFolder/MyButton";
import RadioField from "../CommonFolder/FormStructure/RadioField";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import { useParams, NavLink } from "react-router-dom";

const SelectCharity = () => {
  const { id } = useParams();

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center ">
            <div className="col-lg-9">
              <div className="Full_Table">
                <TableHeader title="Donate Now" />

                <div className="container Donate_Section">
                  <div className="row">
                    <h5>Please select the charity of your choice</h5>
                    <p>A % of the proceeds of all the products you will purchase will be donated to the charity of your choice.</p>
                    <div className="Donate_Options">
                      <RadioField InputType="radio" Radiovalue="American Heart Association" />
                      <RadioField InputType="radio" Radiovalue="Americares Foundation" />
                      <RadioField InputType="radio" Radiovalue="Direct Relief" />
                      <RadioField InputType="radio" Radiovalue="Feeding America" />
                      <RadioField InputType="radio" Radiovalue="Lutheran Services in America" />
                      <RadioField InputType="radio" Radiovalue="Feed the Children" />
                      <RadioField InputType="radio" Radiovalue="United Way" />
                      <RadioField InputType="radio" Radiovalue="Humane League" />
                      <RadioField InputType="radio" Radiovalue="Mercy For Animals" />
                      <RadioField InputType="radio" Radiovalue="Astraea Lesbian Foundation for Justice" />
                      <RadioField InputType="radio" Radiovalue="Bob Woodruff Foundation" />
                      <RadioField InputType="radio" Radiovalue="Homes For Our Troops" />
                      <RadioField InputType="radio" Radiovalue="Human Rights First" />
                      <RadioField InputType="radio" Radiovalue="Humane League" />
                      <RadioField InputType="radio" Radiovalue="Feeding America" />
                      <RadioField InputType="radio" Radiovalue="City Harvest" />
                      <RadioField InputType="radio" Radiovalue="Food for the Hungry" />
                      <RadioField InputType="radio" Radiovalue="American Forests" />
                      <RadioField InputType="radio" Radiovalue="Earthjustice" />
                      <RadioField InputType="radio" Radiovalue="Alzheimer's Association" />
                      <RadioField InputType="radio" Radiovalue="Children's Health Fund" />
                      <div>
                        <RadioField InputType="radio" />
                        <InputFiled Inputplaceholder="Enter custom name" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Table_foot">
                  <div className="Foot_btn">
                    <MyButton title="Donate Now" data-bs-toggle="modal" data-bs-target="#ThankyouDontae" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*========== here is start ThankyouDontae ========== */}

      <div className="modal" id="ThankyouDontae" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog Waiting_pop DonatePopBody">
          <div className="modal-content border-0 ">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body p-0 text-center ">
              <img className="" src="/assets/Images/Otherimages/dislike_image.svg" alt="" />
              <h4>Thank you</h4>
              <p>A % of the proceeds will be donated to your selected charity when your deal is finalized, and we will notify you when that is done. </p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <NavLink to={`/dashboard/${id}`}>
                <MyButton title="Continue" data-bs-dismiss="modal" aria-label="Close" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectCharity;
