import React, { useRef, useState } from "react";

import { useAudio } from "../../../AudioContext";
import { HiShare } from "react-icons/hi";

const TableHeader = ({ title, showFilters, showSareLink, Monthlyfinance, onSearch, onFilterChange }) => {
  const searchInputRef = useRef(null);
  const [selectedDealType, setSelectedDealType] = useState("");
  const [selectedClass, setSelectedClass] = useState("");

  // const play = (audioPath) => {
  //   const audio = new Audio(audioPath);
  //   audio.play().catch((error) => {
  //     console.error("Audio playback failed:", error);
  //   });
  // };
  const { playClickSound } = useAudio();
  // const playAudio = () => {
  //   alert("I am clickedddd");
  //   play("/assets/Audios/clicksoundeffect.mp3");
  // };

  const handleSearchChange = () => {
    if (onSearch && searchInputRef.current) {
      onSearch(searchInputRef.current.value);
    }
  };

  // const handleDealTypeChange = (e) => {
  //   const value = e.target.value || "AllDeal";
  //   setSelectedDealType(value);
  // };

  // const handleClassChange = (e) => {
  //   const value = e.target.value || "AllClass";
  //   setSelectedClass(value);
  // };

  const handleDealTypeChange = (e) => {
    const value = e.target.value;
    setSelectedDealType((prev) => (prev === value ? "" : value));
  };

  const handleClassChange = (e) => {
    const value = e.target.value;
    setSelectedClass((prev) => (prev === value ? "" : value));
  };

  const SearchData = () => {
    playClickSound();
    // play("/assets/Audios/clicksoundeffect.mp3");

    const dealType = selectedDealType || "AllDeal";
    const classType = selectedClass || "AllClass";

    if (onFilterChange) {
      onFilterChange({ dealType, classType });
    }
  };

  return (
    <>
      <div className="table_header">
        <h3>{title}</h3>
        {showFilters && (
          <div className="Table_filters">
            <div className="dropdown d-grid">
              <a href="/" className="Filter" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={playClickSound}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M11.6667 20C11.4864 20 11.311 19.9415 11.1667 19.8333L7.83341 17.3333C7.72991 17.2557 7.64591 17.1551 7.58805 17.0393C7.53019 16.9236 7.50007 16.796 7.50007 16.6667V11.9833L1.6534 5.40583C1.23817 4.9374 0.967048 4.35901 0.872632 3.7402C0.778216 3.12138 0.864519 2.48846 1.12117 1.91751C1.37782 1.34656 1.79389 0.861882 2.31938 0.521721C2.84488 0.181561 3.45742 0.000395349 4.0834 0L15.9167 0C16.5427 0.000734209 17.1551 0.182199 17.6803 0.522589C18.2056 0.862978 18.6214 1.34781 18.8778 1.91882C19.1342 2.48983 19.2202 3.12273 19.1256 3.74146C19.0309 4.36018 18.7596 4.93841 18.3442 5.40667L12.5001 11.9833V19.1667C12.5001 19.3877 12.4123 19.5996 12.256 19.7559C12.0997 19.9122 11.8878 20 11.6667 20Z"
                    fill="white"
                  />
                </svg>
                <span>Filter</span>
                <svg className="Rotate-Filter" xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7" fill="none">
                  <path
                    d="M12.8719 0.758824C12.7906 0.676811 12.6938 0.611716 12.5872 0.567293C12.4806 0.522871 12.3662 0.5 12.2507 0.5C12.1352 0.5 12.0208 0.522871 11.9142 0.567293C11.8076 0.611716 11.7108 0.676811 11.6294 0.758824L7.62194 4.76632C7.5406 4.84834 7.44382 4.91343 7.3372 4.95785C7.23057 5.00228 7.1162 5.02515 7.00069 5.02515C6.88518 5.02515 6.77082 5.00228 6.66419 4.95785C6.55756 4.91343 6.46079 4.84834 6.37944 4.76632L2.37194 0.758824C2.2906 0.676811 2.19382 0.611716 2.0872 0.567293C1.98057 0.522871 1.8662 0.5 1.75069 0.5C1.63518 0.5 1.52082 0.522871 1.41419 0.567293C1.30756 0.611716 1.21079 0.676811 1.12944 0.758824C0.966474 0.922766 0.875 1.14454 0.875 1.3757C0.875 1.60686 0.966474 1.82863 1.12944 1.99257L5.14569 6.00882C5.63788 6.5004 6.30507 6.77651 7.00069 6.77651C7.69632 6.77651 8.3635 6.5004 8.85569 6.00882L12.8719 1.99257C13.0349 1.82863 13.1264 1.60686 13.1264 1.3757C13.1264 1.14454 13.0349 0.922766 12.8719 0.758824Z"
                    fill="white"
                  />
                </svg>
              </a>

              <div className="dropdown-menu">
                <div className="border-bottom p-3">
                  <h5>Deal Type</h5>
                  <div className="d-flex align-items-center gap-2">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="AllDeal" name="DealType" onClick={playClickSound} checked={selectedDealType === "AllDeal"} onChange={handleDealTypeChange} />
                      <label className="form-check-label">All</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Cash" name="DealType" onClick={playClickSound} checked={selectedDealType === "Cash"} onChange={handleDealTypeChange} />
                      <label className="form-check-label">Cash</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Finance" name="DealType" onClick={playClickSound} checked={selectedDealType === "Finance"} onChange={handleDealTypeChange} />
                      <label className="form-check-label">Finance</label>
                    </div>
                  </div>
                </div>

                <div className="border-bottom p-3 mb-3">
                  <h5>Class</h5>
                  <div className="d-flex align-items-center gap-2 flex-wrap">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="AllClass" name="ClassType" onClick={playClickSound} checked={selectedClass === "AllClass"} onChange={handleClassChange} />
                      <label className="form-check-label">All</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="New" name="ClassType" onClick={playClickSound} checked={selectedClass === "New"} onChange={handleClassChange} />
                      <label className="form-check-label">New</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Used" name="ClassType" onClick={playClickSound} checked={selectedClass === "Used"} onChange={handleClassChange} />
                      <label className="form-check-label">Used</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="Certified Pre-Owned" name="ClassType" onClick={playClickSound} checked={selectedClass === "Certified Pre-Owned"} onChange={handleClassChange} />
                      <label className="form-check-label">Certified Pre-Owned</label>
                    </div>
                  </div>
                </div>
                <div className="filter_earch_btn mb-2">
                  <button type="button" className="" onClick={SearchData}>
                    SEARCH
                  </button>
                </div>
              </div>
            </div>

            <div className="Search">
              <input type="text" id="search" placeholder="Search Deal Here.." ref={searchInputRef} onChange={handleSearchChange} />
            </div>
          </div>
        )}
        {showSareLink && (
          <div className="ShareLink">
            <a href="/" data-bs-toggle="modal" data-bs-target="#shareLinkModal" onClick={playClickSound}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="HiShareInHeader">
                <g clipPath="url(#clip0_122_2834)">
                  <g clipPath="url(#clip1_122_2834)">
                    <path
                      d="M16.1109 12.2224C15.4803 12.2226 14.8593 12.3761 14.3014 12.6698C13.7435 12.9635 13.2654 13.3885 12.9084 13.9082L7.49169 11.4624C7.87155 10.5296 7.87454 9.48571 7.50003 8.55072L12.9117 6.09322C13.4394 6.85884 14.2241 7.40995 15.1233 7.64656C16.0226 7.88317 16.9769 7.78961 17.813 7.38286C18.6492 6.97611 19.3119 6.2831 19.6809 5.42958C20.0499 4.57605 20.1007 3.61854 19.8241 2.73075C19.5475 1.84297 18.9619 1.08373 18.1734 0.59077C17.385 0.0978114 16.446 -0.0962085 15.5267 0.0439098C14.6075 0.184028 13.7689 0.649002 13.1631 1.35448C12.5574 2.05995 12.2245 2.95919 12.225 3.88906C12.225 4.11024 12.2437 4.33102 12.2809 4.54906L6.53836 7.15572C5.98477 6.63942 5.29253 6.29589 4.54655 6.16727C3.80056 6.03865 3.03325 6.13053 2.33873 6.43165C1.6442 6.73276 1.05265 7.23002 0.636609 7.86243C0.220572 8.49485 -0.00186019 9.23494 -0.00340982 9.99193C-0.00495944 10.7489 0.214441 11.4899 0.627885 12.124C1.04133 12.7581 1.63084 13.2578 2.32413 13.5618C3.01742 13.8657 3.78434 13.9607 4.53085 13.8352C5.27736 13.7096 5.97099 13.3689 6.52669 12.8549L12.2767 15.4524C12.1415 16.2383 12.2512 17.0467 12.5909 17.7682C12.9305 18.4896 13.4838 19.0892 14.1756 19.4858C14.8674 19.8823 15.6644 20.0566 16.4586 19.985C17.2528 19.9135 18.0058 19.5995 18.6156 19.0857C19.2254 18.5719 19.6625 17.883 19.8678 17.1125C20.073 16.3419 20.0364 15.5269 19.7629 14.7778C19.4895 14.0288 18.9924 13.3819 18.339 12.9248C17.6856 12.4677 16.9083 12.2225 16.1109 12.2224Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_122_2834">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_122_2834">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span>Share a Link</span>
            </a>
          </div>
        )}
        {Monthlyfinance && (
          <div className="Monthly_Finance_text">
            <p className="m-0">Monthly finance payment estimate</p>
          </div>
        )}
      </div>
    </>
  );
};

export default TableHeader;
