import React, { useState } from "react";
import axios from "axios";
import MyButton from "../../CommonFolder/MyButton";
import InputFiled from "../../CommonFolder/FormStructure/InputField";
import { useNavigate } from "react-router-dom";
import validationRules from "../../CommonFolder/FormStructure/validationRules.json";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const email = localStorage.getItem("email");
  const otp = localStorage.getItem("otp");

  const validateForm = () => {
    const newErrors = {};

    // Validate password
    if (validationRules.password) {
      const passwordRules = validationRules.password;
      if (passwordRules.required && !password) {
        newErrors.password = passwordRules.messages.required;
      } else if (passwordRules.minLength && password.length < passwordRules.minLength) {
        newErrors.password = passwordRules.messages.minLength;
      } else if (passwordRules.maxLength && password.length > passwordRules.maxLength) {
        newErrors.password = passwordRules.messages.maxLength;
      } else if (passwordRules.pattern && !new RegExp(passwordRules.pattern).test(password)) {
        newErrors.password = passwordRules.messages.pattern;
      }
    }

    // Validate confirm password
    if (!cPassword) {
      newErrors.cPassword = "Confirm password is required.";
    } else if (cPassword !== password) {
      newErrors.cPassword = "Passwords do not match.";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    // Run validation
    if (!validateForm()) {
      return;
    }

    if (!email || !otp) {
      setError({ general: "Email or OTP not found. Please go back and verify." });
      return;
    }

    try {
      const response = await axios.post("https://api.menuverse.ai/users/reset-password", {
        email,
        otp: Number(otp), // Convert OTP to a number
        password,
        c_password: cPassword,
      });

      if (response.status === 200) {
        navigate("/login");
        localStorage.removeItem("email");
        localStorage.removeItem("otp");
      }
    } catch (error) {
      setError({ general: "Failed to change password. Please try again." });
      console.error("API error:", error);
    }
  };

  return (
    <>
      <form className="row g-4 w-100" onSubmit={handleSubmit}>
        <div className="FormBox">
          <div className="position-relative">
            <InputFiled InputBoxClassname="col-md-12" InputType="password" Inputplaceholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            {error.password && <p className="ChangePassword-Error-msg">{error.password}</p>}
          </div>
          <div className="position-relative">
            <InputFiled InputBoxClassname="col-md-12" InputType="password" Inputplaceholder="Re-enter Password" value={cPassword} onChange={(e) => setCPassword(e.target.value)} />
            {error.cPassword && <p className="ChangePassword-Error-msg">{error.cPassword}</p>}
          </div>
          {error.general && <p className="text-danger">{error.general}</p>}

          <MyButton title="Change Password" className="w-100 MyBtn" type="submit" />
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
