import React from "react";

const InputFiled = ({ InputType, value, InputID, InputBoxClassname, lableValue, required, Inputplaceholder, InputName, disabled, customClassName, onChange, readOnly, maxlength, minlength }) => {
  return (
    <>
      <div className={InputBoxClassname} disabled={disabled} required={required}>
        <label className="form-label">{lableValue}</label>
        <input type={InputType} value={value} id={InputID} name={InputName} className={`${customClassName ? customClassName : ""} ${customClassName ? "" : "form-control"}`} required={required} placeholder={Inputplaceholder} disabled={disabled} onChange={onChange} readOnly={readOnly} maxLength={maxlength} minLength={minlength} />
      </div>
    </>
  );
};

export default InputFiled;
