import React, { useState, useRef, useEffect } from "react";
import "../../Dashboard.css";
import ProductItems from "./ProductItems";
import { useParams } from "react-router-dom";
import TermAmountCalulation from "./TermAmountCalulation";
import MyButton from "../../../CommonFolder/MyButton";
import SignatureCanvas from "react-signature-canvas";
import InputFiled from "../../../CommonFolder/FormStructure/InputField";
import { useAudio } from "../../../../AudioContext";
import axios from "axios";

const DashboardLeftSidebar = ({ menuData, emiData, second_emiData, interestData, termData, second_termData, downData, onSaveMenu, ProductShowAll }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [InputChecked, setInputChecked] = useState(false);
  const sigCanvas = useRef({});
  const { playClickSound } = useAudio();
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const fileInputRef = useRef(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [isCanvasDisabled, setIsCanvasDisabled] = useState(true);
  const [uploadedSignatureImage, setUploadedSignatureImage] = useState(false);
  const [SharePolicieDisable, setSharePolicieDisable] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [recommendedServices, setRecommendedServices] = useState([]);
  const { id } = useParams();
  const usertoken = localStorage.getItem("token");

  // console.log("menu", menuData);
  if (Array.isArray(menuData)) {
    menuData = menuData[0];
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const InputCheckboxChange = () => {
    setInputChecked((prev) => !prev);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setInputChecked(false);
    setIsSignaturePresent(false);
    setInputChecked(false);
    setSignatureImage(null);
    setUploadedSignatureImage(false);
  };

  const checkSignature = () => {
    // Check if the signature canvas is empty
    setIsSignaturePresent(!sigCanvas.current.isEmpty());
  };

  //  download sign function ====>>>>>>>>>>

  const downloadSignature = () => {
    if (signatureImage) {
      // If the wet signature image is uploaded, download it
      const link = document.createElement("a");
      link.href = signatureImage; // Use the uploaded signature image URL
      link.download = "wet-signature.png";
      link.click();
    } else if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      // Otherwise, download the e-signature from the canvas
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "signature.png";
      link.click();
    }
  };

  const handleAcceptSign = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      setSignatureImage(dataURL);
    }
  };

  const handleWetSignClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Use FileReader to read the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignatureImage(reader.result); // Set the image URL in state
        setIsCanvasDisabled(true);
        sigCanvas.current.clear();
        // setIsSignaturePresent if user upload sign img
        setIsSignaturePresent(true);
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const handleSaveMenu = () => {
    if (onSaveMenu) {
      onSaveMenu();
    }
  };
  const content =
    ProductShowAll === 1 ? (
      <div>
        <p>Your vehicle is partially Protected. Make it fully secured by completing your coverage.</p>
      </div>
    ) : ProductShowAll === 2 ? (
      <div>
        <p>Secure your peace of mind on the road. Upgrade to below warranties.</p>
      </div>
    ) : (
      <div>
        <p>Congratulations! </p>
        <p>Your vehicle is fully Protected.</p>
      </div>
    );

  const ViewSignImg = () => {
    setUploadedSignatureImage((prev) => !prev);
  };

  const handleshareImage = () => {
    setUploadedSignatureImage(false);
    clearSignature();
  };

  // UseEffect to handle enabling/disabling button
  useEffect(() => {
    if (email || phone) {
      setSharePolicieDisable(false);
    } else {
      setSharePolicieDisable(true);
    }
  }, [email, phone]);

  // Handle Share Policy action and console log the input values
  const handleSharePolicie = () => {
    console.log("Email:", email);
    console.log("Phone:", phone);
    setEmail("");
    setPhone("");
  };

  // Fetch recommended_service names from API
  useEffect(() => {
    const fetchRecommendedServices = async () => {
      try {
        const response = await axios.get(`https://api.menuverse.ai/dealWarranties/show/${id}`, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        });

        const recommendedServicesArray = response.data.data.map((item) => item.recommended_service);
        setRecommendedServices(recommendedServicesArray);
      } catch (error) {
        console.error("Error fetching recommended services:", error);
      }
    };

    fetchRecommendedServices();
  }, []);

  // Filter out empty strings and create a Set to remove duplicates
  const uniqueServices = [...new Set(recommendedServices.filter((service) => service.trim() !== ""))];
  const isProductRecommended = (title) => uniqueServices.includes(title);

  return (
    <>
      <div className={`col-lg-3 Dashboard-Left-Sidebar ${ProductShowAll === 1 ? "Bg-Warning" : ProductShowAll === 2 ? "Bg-Danger" : ""}`}>
        <div className="head">
          {ProductShowAll === 1 ? <img src="/assets/Images/DashboardImages/triangle-warning.svg" alt="" /> : ProductShowAll >= 2 ? <img src="/assets/Images/DashboardImages/triangle-danger.svg" alt="" /> : ProductShowAll === 0 ? <img src="/assets/Images/DashboardImages/checkVector.svg" alt="" /> : <img src="/assets/Images/DashboardImages/checkVector.svg" alt="" />}

          <div>{content}</div>
        </div>
        {/*========== here is start green sidebar ========== */}

        <div className="Product-Options">
          <div>
            <div className="Recommend_Select_Options">
              {!isChecked && (
                <p>
                  Lock in Peace of Mind: Explore <br /> Our Vehicle Product Options
                </p>
              )}
              {isChecked && (
                <p>
                  <span>VERSA</span> recommendations based on your custom ownership journey
                </p>
              )}
              <div className="Versa_Recommends">
                <div>
                  <span>VERSA</span>
                  <p>Recommends</p>
                </div>
                <label className="custom-checkbox">
                  <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} onClick={playClickSound} />
                  <span className="custom-checkmark"></span>
                </label>
              </div>
            </div>
            <div className="Product-Options-Items row row-cols-2  gy-3">
              <ProductItems ProductItemsTitle="VSC" ProductItemsPrice={menuData?.VSC?.price ?? "47.00"} ProuctImage="tools.svg" productDetail={menuData?.VSC} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("VSC") ? isChecked : false} AcDeactive={!isProductRecommended("VSC") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProductItemsTitle="GAP" ProductItemsPrice={menuData?.GAP?.price ?? "26.54"} ProuctImage="car-crash.svg" productDetail={menuData?.GAP} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("GAP") ? isChecked : false} AcDeactive={!isProductRecommended("GAP") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProductItemsTitle="PPM" ProductItemsPrice={menuData?.PPM?.price ?? "11.00"} ProuctImage="under-maintenance.svg" productDetail={menuData?.PPM} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("PAINTLESS DENT REMOVAL") ? isChecked : false} AcDeactive={!isProductRecommended("PAINTLESS DENT REMOVAL") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProductItemsTitle="Anti-theft" ProductItemsPrice={menuData?.anti_theft?.price ?? "0.00"} ProuctImage="anti-theft-system.svg" productDetail={menuData?.anti_theft} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("Anti-theft") ? isChecked : false} AcDeactive={!isProductRecommended("Anti-theft") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProuctImage="car-painting.svg" ProductItemsTitle="PDR" ProductItemsPrice={menuData?.PDR?.price ?? "14.00"} productDetail={menuData?.PDR} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("PRE-PAID MAINTENANCE") ? isChecked : false} AcDeactive={!isProductRecommended("PRE-PAID MAINTENANCE") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProuctImage="windshield.svg" ProductItemsTitle="Windshield Protection" ProductItemsPrice={menuData?.windshield_protection?.price ?? "8.99"} productDetail={menuData?.windshield_protection} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("Windshield Protection") ? isChecked : false} AcDeactive={!isProductRecommended("Windshield Protection") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProuctImage="layers.svg" ProductItemsTitle="TotalCase Bundle" ProductItemsPrice={menuData?.TB?.price ?? "55.00"} productDetail={menuData?.TB} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("TotalCase Bundle") ? isChecked : false} AcDeactive={!isProductRecommended("TotalCase Bundle") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProuctImage="tierVector.svg" ProductItemsTitle="TWL" ProductItemsPrice={menuData?.TWL?.price ?? "12.00"} productDetail={menuData?.TWL} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("TIRE AND WHEEL PROTECTION") ? isChecked : false} AcDeactive={!isProductRecommended("TIRE AND WHEEL PROTECTION") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProuctImage="tools.svg" ProductItemsTitle="Key-Replacement" ProductItemsPrice={menuData?.key_replacement?.price ?? "6.00"} productDetail={menuData?.key_replacement} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("Key-Replacement") ? isChecked : false} AcDeactive={!isProductRecommended("Key-Replacement") ? (isChecked ? "Deactive" : "") : ""} />

              <ProductItems ProuctImage="layers.svg" ProductItemsTitle="LWT" ProductItemsPrice={menuData?.LWT?.price ?? "0.00"} productDetail={menuData?.LWT} interestData={interestData} termData={termData} second_termData={second_termData} onSaveMenu={handleSaveMenu} Option_Logo={isProductRecommended("LIMITED WARRANTY") ? isChecked : false} AcDeactive={!isProductRecommended("LIMITED WARRANTY") ? (isChecked ? "Deactive" : "") : ""} />
            </div>
          </div>
          <div className="Left_Sidebar_Footer">
            <div className="Term_Amount_Calulation">
              {" "}
              <TermAmountCalulation emi={emiData ?? "620.80"} interest={interestData ?? "8"} term={termData ?? "72"} down={downData ?? "0.00"} />
              <TermAmountCalulation emi={second_emiData ?? "780.90"} interest={interestData ?? "60"} term={second_termData ?? "8"} down={downData ?? "0.00"} />
            </div>
            <button className="Sign_And_Verify" data-bs-toggle="modal" data-bs-target="#ESignature" onClick={playClickSound}>
              Sign & Verify
            </button>
          </div>
        </div>
      </div>

      {/*========== here is start modal ESignature ========== */}

      <div className="modal" id="ESignature" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog ESignatureMoadal">
          <div className="modal-content border-0">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              ></button>

              <img src="/assets/Images/DashboardImages/E-signatureimg.svg" alt="" />
              <h4>E-signature</h4>
            </div>
            <div className="modal-body text-center p-0">
              <div className="ESignatureTextarea">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={checkSignature}
                  disabled={isCanvasDisabled}
                />
                <a onClick={clearSignature}>Clear</a>
                {signatureImage && (
                  <div className="signature-preview">
                    <img src={signatureImage} alt="Wet Signature" style={{ maxWidth: "100%", height: "auto" }} />
                  </div>
                )}
              </div>

              <div className="Checkbox_input">
                <input className="form-check-input" type="checkbox" checked={InputChecked} onChange={InputCheckboxChange} onClick={playClickSound} />
                <span>I agree with all my purchases.</span>
              </div>
            </div>
            <div className="modal-footer  justify-content-center">
              <button
                className="Cancel_Btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              >
                Cancel
              </button>
              <button className="WetSign_Btn" onClick={handleWetSignClick}>
                Wet Sign
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleFileChange}
              />
              <button className=" MyBtn" data-bs-toggle="modal" data-bs-target="#GREATJOB" disabled={!InputChecked || !isSignaturePresent} onClick={handleAcceptSign}>
                Accept E-Sign
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start modal GREATJOB ========== */}

      <div className="modal" id="GREATJOB" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                playClickSound();
                clearSignature();
              }}
            ></button>
            <div className="modal-body p-0 text-center ">
              {!uploadedSignatureImage ? (
                <div>
                  <img className="" src="/assets/Images/Otherimages/dislike_image.svg" alt="GREATJOB" />
                  <h4>Great Job!</h4>
                  <p>We encourage you to share the final copy to your email/phone no. rather than printing it to save trees.</p>
                </div>
              ) : (
                <div className="User-SignImage">
                  <img src={signatureImage} alt="" />
                </div>
              )}
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <button className="Empty_Btn border-0" onClick={downloadSignature}>
                Print
              </button>
              <button className="bgColor_Btn" onClick={ViewSignImg}>
                {!uploadedSignatureImage ? "View" : "Hide"}
              </button>
              <button onClick={handleshareImage} className="MyBtn" data-bs-toggle="modal" data-bs-target="#Share_Policies">
                Share
              </button>
            </div>
          </div>
          <div className="GREATJOB_Message">
            <p>Save 2 pounds of wood by choosing “SHARE” option. This will eventually help to reduce carbon footprints and save our mother earth.</p>
          </div>
        </div>
      </div>

      {/*========== here is start modal Share Policies ========== */}

      <div className="modal" id="Share_Policies" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog DashboardModals p-4">
          <div className="modal-content p-0 border-0 Edit_Product">
            <div className="modal-header justify-content-start gap-2 p-4">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={playClickSound}></button>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none" className="HiShare">
                <g clipPath="url(#clip0_141_11738)">
                  <path
                    d="M16.1111 12.2225C15.4806 12.2227 14.8596 12.3762 14.3017 12.6699C13.7437 12.9636 13.2656 13.3887 12.9086 13.9083L7.49194 11.4625C7.8718 10.5297 7.87479 9.48583 7.50027 8.55085L12.9119 6.09335C13.4396 6.85896 14.2243 7.41007 15.1236 7.64668C16.0228 7.88329 16.9771 7.78974 17.8133 7.38298C18.6495 6.97623 19.3122 6.28323 19.6811 5.4297C20.0501 4.57617 20.1009 3.61866 19.8243 2.73088C19.5478 1.84309 18.9621 1.08385 18.1737 0.590892C17.3852 0.0979334 16.4462 -0.0960864 15.527 0.0440319C14.6077 0.18415 13.7692 0.649124 13.1634 1.3546C12.5576 2.06007 12.2248 2.95931 12.2253 3.88918C12.2253 4.11036 12.2439 4.33114 12.2811 4.54918L6.5386 7.15585C5.98501 6.63954 5.29278 6.29601 4.54679 6.16739C3.8008 6.03877 3.0335 6.13066 2.33897 6.43177C1.64444 6.73288 1.05289 7.23014 0.636853 7.86256C0.220816 8.49497 -0.00161605 9.23506 -0.00316568 9.99205C-0.0047153 10.749 0.214685 11.49 0.628129 12.1241C1.04157 12.7583 1.63109 13.2579 2.32438 13.5619C3.01766 13.8658 3.78459 13.9609 4.53109 13.8353C5.2776 13.7097 5.97124 13.369 6.52694 12.855L12.2769 15.4525C12.1418 16.2384 12.2515 17.0468 12.5911 17.7683C12.9308 18.4897 13.484 19.0893 14.1758 19.4859C14.8677 19.8824 15.6647 20.0567 16.4589 19.9852C17.2531 19.9136 18.0061 19.5996 18.6159 19.0858C19.2257 18.572 19.6628 17.8831 19.868 17.1126C20.0732 16.342 20.0366 15.527 19.7632 14.778C19.4897 14.0289 18.9926 13.382 18.3392 12.9249C17.6858 12.4678 16.9085 12.2226 16.1111 12.2225Z"
                    fill="#2E302E"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_141_11738">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <h4>Share Policies</h4>
            </div>
            <div className="modal-body p-4">
              <div className="gap-2 d-flex flex-column">
                <InputFiled onChange={(e) => setEmail(e.target.value)} value={email} lableValue="Email address" InputType="emial" Inputplaceholder="cynthiaj@gmail.com" />
                <b className="text-center">or</b>
                <InputFiled onChange={(e) => setPhone(e.target.value)} value={phone} lableValue="Phone number" InputType="number" Inputplaceholder="(925) 676-0200" maxlength="12" minlength="10" />
              </div>
            </div>
            <div className="modal-footer justify-content-center p-4">
              <button className="w-100 MyBtn" data-bs-toggle="modal" data-bs-target="#Policy_Shared" disabled={SharePolicieDisable} onClick={handleSharePolicie}>
                Share Now
              </button>
            </div>
          </div>
          <div className="GREATJOB_Message">
            <p>You’ve unlocked 300 reward points that you can utilize when you come again for your vehicle service. Don’t forget to remind us.</p>
          </div>
        </div>
      </div>

      {/*========== here is start modal Policy Shared ========== */}

      <div className="modal" id="Policy_Shared" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={playClickSound}></button>
            <div className="modal-body p-0 text-center ">
              <img className="" src="/assets/Images/Otherimages/dislike_image.svg" alt="GREATJOB" />
              <h4>Policy Shared</h4>
              <p>A link has been shared on your email and phone number. You can download the policy by clicking on that link</p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton title="Share Review" data-bs-toggle="modal" data-bs-target="#Reviewsmodal"></MyButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLeftSidebar;
