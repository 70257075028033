import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const VerifyCode = () => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [otp, setOtp] = useState(["", "", "", ""]); // State to store OTP digits
  const [error, setError] = useState(null); // For displaying errors
  const navigate = useNavigate();

  const handleChange = (index, e) => {
    const inputLength = e.target.value.length;
    const value = e.target.value;

    // Update OTP state with the new input value
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (inputLength === 0 && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (inputLength === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const email = localStorage.getItem("email");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const otpCode = otp.join("");
      const numericOtpCode = Number(otpCode);

      const response = await axios.post("https://api.menuverse.ai/users/reset-password", {
        email: email,
        otp: numericOtpCode,
      });

      if (response.status) {
      }
    } catch (error) {
      if (error.response.data.data.message == "hash failed") {
        const otpString = otp.join("");
        localStorage.setItem("otp", otpString);

        navigate("/ChangePassword");
      } else {
        setError("Invalid OTP. Please try again.");
        console.error("Error verifying OTP:", error);
      }
    }
  };

  return (
    <>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-12">
          <div className="OTP_Boxes mb-3">
            {/* Map over inputRefs array to render input elements */}
            {inputRefs.map((ref, index) => (
              <input
                key={index}
                type="text"
                className="form-control"
                maxLength={1}
                ref={ref}
                onChange={(e) => handleChange(index, e)}
                onKeyPress={handleKeyPress}
                value={otp[index]} // Set value from OTP state
              />
            ))}
          </div>
          {error && <p className="text-danger">{error}</p>}
          <button className="w-100 MyBtn" type="submit">
            Verify Code
          </button>
        </div>
      </form>
    </>
  );
};

export default VerifyCode;
