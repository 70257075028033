import React, { useRef, useEffect, useState, useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import LoadableImage from "../CommonFolder/CommonImages/LoadableImage";
import MyButton from "../CommonFolder/MyButton";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useVideo } from "../../VideoContext";
import { useAudio } from "../../AudioContext";

const Presentation = () => {
  const { playPreloadedVideo, playPreloadedThreeVideos, isVideoEndedinfinityVideoSrc } = useVideo();
  const { playClickSound } = useAudio();

  const [isPlaying, setIsPlaying] = useState(true);
  const { id } = useParams();
  const modalVideoRef = useRef(null);
  const pageVideoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [HidePagevideo, setHidePagevideo] = useState(false);
  const [firstName, setFirstName] = useState("");

  // const play = (audioPath) => {
  //   const audio = new Audio(audioPath);
  //   audio.play().catch((error) => {
  //     console.error("Audio playback failed:", error);
  //   });
  // };
  // const playAudio = () => {
  //   play("/assets/Audios/clicksoundeffect.mp3");
  // };

  useEffect(() => {
    const storedFirstName = localStorage.getItem("firstName");
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
  }, []);

  const handleVideoPlayback = useMemo(() => {
    return () => {
      if (modalVideoRef.current) {
        if (isModalOpen) {
          modalVideoRef.current.play();
          setHidePagevideo(true);
        } else {
          modalVideoRef.current.pause();
        }
      }
    };
  }, [isModalOpen]);

  useEffect(() => {
    handleVideoPlayback();
  }, [handleVideoPlayback]);

  const toggleModalVideoPlay = () => {
    const video = modalVideoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const togglePageVideoPlay = () => {
    const video = pageVideoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const playVideo = () => {
    const video = pageVideoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = pageVideoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = pageVideoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (isVideoEndedinfinityVideoSrc) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isVideoEndedinfinityVideoSrc]);

  return (
    <>
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 Hero_section p-0 position-relative Presentation_left_box">
              <LoadableImage src="/Images/Group68hero.png" alt="sdsdfsf" />

              <NavLink className="play-Aniamtion" data-bs-toggle="modal" data-bs-target="#InfintyCycleVideo" onClick={() => setIsModalOpen(true)}>
                {/* <img className="VideoPlayImag" src="/assets/Images/OtherImages/VideoPlayImag.png" alt="VideoPlay Image" /> */}

                <svg className="VideoPlayImag" xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114" fill="none">
                  <circle cx="57" cy="57" r="57" fill="white" />
                  <g clipPath="url(#clip0_32_11705)">
                    <path
                      d="M70.7519 50.4725L55.3068 39.147C54.1005 38.2637 52.6731 37.7316 51.1828 37.6098C49.6926 37.488 48.1978 37.7813 46.864 38.457C45.5302 39.1328 44.4096 40.1647 43.6264 41.4384C42.8432 42.712 42.428 44.1777 42.4268 45.6729V68.3352C42.4244 69.8319 42.8375 71.2998 43.62 72.5757C44.4024 73.8515 45.5236 74.8852 46.8587 75.5616C48.1937 76.238 49.6903 76.5307 51.1819 76.407C52.6734 76.2833 54.1014 75.7482 55.3068 74.8611L70.7519 63.5356C71.7767 62.7835 72.6101 61.8006 73.1845 60.6666C73.7589 59.5326 74.0582 58.2793 74.0582 57.0081C74.0582 55.7369 73.7589 54.4836 73.1845 53.3496C72.6101 52.2155 71.7767 51.2327 70.7519 50.4806V50.4725Z"
                      fill="#179B9B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_32_11705">
                      <rect width="38.8636" height="38.8636" fill="white" transform="translate(37.5684 37.5682)" />
                    </clipPath>
                  </defs>
                </svg>
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-12 Content">
              <div>
                <NavLink to={`/dashboard/${id}`} onClick={playClickSound}>
                  Skip presentation <FaArrowRightLong />
                </NavLink>
              </div>
              <div className="Main_text">
                <h1>
                  <span>{firstName}’s </span>Vehicle <br /> Life cycle
                </h1>
                <p>
                  Delivering an unparalleled customer experience <br /> through Cutting-edge Machine Learning.
                </p>
                <NavLink data-bs-toggle="modal" data-bs-target="#InfintyCycleVideo" onClick={() => setIsModalOpen(true)}>
                  <span onClick={playClickSound}> Watch Now</span>
                </NavLink>
                <img src="/assets/Images/OtherImages/LeftArrow.svg" alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* here is my popup  */}

        <div className="modal" id="InfintyCycleVideo" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={() => setIsModalOpen(false)}>
          <div className="modal-dialog Waiting_pop Loop_Video_Modal">
            <div className="modal-content border-0 bg-transparent">
              <div className="modal-body p-0">
                <div className="background-video position-static" onClick={toggleModalVideoPlay}>
                  <video className="w-100" id="myVideo" ref={modalVideoRef} autoPlay loop preload="none">
                    <source src="../assets/Videos/PageVideos/carloopvideo2.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="modal-footer border-0 justify-content-center pb-0">
                <NavLink to={`/dashboard/${id}`}>
                  <MyButton title="Let’s Proceed" onClick={toggleModalVideoPlay} className="Secondry_Btn" data-bs-dismiss="modal" aria-label="Close" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        {/* here is versa aia video  */}

        {!HidePagevideo && (
          <div className="DetailVerification_Versa">
            <div className="DetailVerification_Versa_Bottons">
              {isPlaying ? (
                <button onClick={pauseVideo}>
                  <FaPause />
                </button>
              ) : (
                <button onClick={playVideo}>
                  <FaPlay />
                </button>
              )}
              <button onClick={replayVideo}>
                <MdOutlineReplay />
              </button>
            </div>
            <div className="Bot_Buttons Big-size-background-Ai-video">
              <video ref={pageVideoRef} onClick={togglePageVideoPlay} autoPlay></video>
              <img
                onClick={togglePageVideoPlay}
                className="Big-size-background-Ai"
                src="/assets/Images/DashboardImages/newversaNew.jpg"
                alt=""
                onLoad={() => {
                  playPreloadedVideo("infinityVideoSrc", pageVideoRef);
                  playPreloadedThreeVideos("../assets/Videos/referSomeone.webm", "../assets/Videos/PageVideos/infinityPage.webm", "../assets/Videos/PageVideos/welcomeToMenu.webm", "referVideoSrc", "infinityVideoSrc", "welcomToMenu");
                }}
              />
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default Presentation;
