import React, { useState, useEffect, useRef } from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import Modal from "../Deals/Modal";
import MyButton from "../CommonFolder/MyButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Loader from "../CommonFolder/Loader";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { useAudio } from "../../AudioContext";
import { useVideo } from "../../VideoContext";
import { FaPlay, FaPause, FaShareAlt } from "react-icons/fa";
import { MdOutlineReplay, MdRemoveRedEye } from "react-icons/md";
import { GiCarWheel } from "react-icons/gi";

const Deals = ({ setIsLogin, setSharedState }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [ShareDealId, setShareDealId] = useState(null);
  const [dealToDelete, setDealToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const usertoken = localStorage.getItem("token");
  const [isModalOpen, setModalState] = useState(false);
  const [selectedValueForWaiting, setselectedValueForWaiting] = useState(10);
  const { playClickSound } = useAudio();
  const { playPreloadedVideo, isVideoEnded } = useVideo();
  const [isPlaying2, setIsPlaying2] = useState(true);
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSharedState(value);
    setselectedValueForWaiting(value);
    localStorage.setItem("selectedValueForWaiting", value);
    setSelectedValue(value);
  };

  const handleClick = () => {
    const video = videoRef.current;
    if (video) {
      if (video) {
        video.play();
      } else {
        video.pause();
      }
    }
    if (!selectedValue) {
      setSharedState(10);
    }
    localStorage.setItem("state", selectedState);
  };

  const navigate = useNavigate();
  const videoRef = useRef(null);
  let half = "col-md-12 mb-3";
  const [filters, setFilters] = useState({
    dealType: "AllDeal",
    classType: "AllClass",
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    console.log("Selected Deal Type:", newFilters.dealType);
    console.log("Selected Class:", newFilters.classType);
  };

  useEffect(() => {
    // Calculate rows per page based on the height of the viewport
    const calculateRowsPerPage = () => {
      const availableHeight = window.innerHeight - 343; // 100vh - 343px
      const rows = Math.floor(availableHeight / 55); // Divide by row height (50px)
      setRowsPerPage(rows > 0 ? rows : 5); // Ensure at least 5 row is shown
      // console.log("availableHeight", availableHeight);
    };

    calculateRowsPerPage(); // Run on initial load
    window.addEventListener("resize", calculateRowsPerPage); // Recalculate on window resize

    return () => {
      window.removeEventListener("resize", calculateRowsPerPage);
    };
  }, []);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://api.menuverse.ai/newdeal/show", {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            localStorage.clear();
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        setData(responseData.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        navigate("/login");
      });
  }, []);

  useEffect(() => {
    // First filter by search term
    let filtered = data.filter((deal) => `${deal.firstName} ${deal.lastName} ${deal.vinNumber} ${deal.vehicle} ${deal.vehicleColor} ${deal.phoneNumber}`.toLowerCase().includes(searchTerm.toLowerCase()));

    // Then filter by dealType and classType
    if (filters.dealType !== "AllDeal") {
      filtered = filtered.filter((deal) => deal.deal_type && deal.deal_type.toLowerCase() === filters.dealType.toLowerCase());
    }

    if (filters.classType !== "AllClass") {
      filtered = filtered.filter((deal) => deal.class && deal.class.toLowerCase() === filters.classType.toLowerCase());
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchTerm, data, filters]);

  const handlePageChange = (event, value) => {
    playClickSound();
    setCurrentPage(value);
  };

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying2(true);
    }
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying2(true);
    }
  };

  const pauseVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setIsPlaying2(false);
    }
  };

  // const handleVideoEnd = () => {
  //   console.log("video stoped");
  //   setIsPlaying(false);
  // };

  const handleDelete = (id) => {
    playClickSound();
    fetch(`https://api.menuverse.ai/newdeal/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.message === "new deal deleted successfully") {
          setData(data.filter((deal) => deal.id !== id));
          setFilteredData(filteredData.filter((deal) => deal.id !== id));
        }
      })
      .catch((error) => console.error("Error deleting data:", error));

    navigate("/login");
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const startIdx = (currentPage - 1) * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;
  const paginatedData = filteredData.slice(startIdx, endIdx);

  const handleNavigate = () => {
    navigate(`/DetailVerification/${selectedDealId}`);
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleShown = () => {
      // if (video) {
      //   video.play();
      // }
      playPreloadedVideo("video1", videoRef);
    };

    const handleHidden = () => {
      // if (video) {
      //   video.pause();
      //   video.currentTime = 0;
      // }
    };

    const modalElement = document.getElementById("DealVideoModal");
    if (modalElement) {
      modalElement.addEventListener("shown.bs.modal", handleShown);
      modalElement.addEventListener("hidden.bs.modal", handleHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("shown.bs.modal", handleShown);
        modalElement.removeEventListener("hidden.bs.modal", handleHidden);
      }
    };
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // here is my send mail code start ========================>

  const [email, setEmail] = useState("");
  const userId = localStorage.getItem("userId");

  const closemodal = () => {
    const modalCloseButton = document.querySelector("#mailmodal");
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  };

  const sendEmail = (e) => {
    const isShared = "true";
    const userName = localStorage.getItem("userName");
    e.preventDefault();
    const templateParams = {
      from_name: `${data.find((deal) => deal.id === ShareDealId)?.firstName} ${data.find((deal) => deal.id === ShareDealId)?.lastName}`,
      Email_to: email,
      From_email: "tester5kwt@gmail.com",
      site_link: `https://staging.menuverse.ai/DetailVerification/${ShareDealId}?=${usertoken}?=${userId}?=${userName}?=${isShared}`,
    };

    emailjs
      .send("service_1pgyq2k", "template_pgry7gb", templateParams, "310uxmyu5Xn5Ntdzt")
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        toast.dismiss();
        toast.success("Link shared Successfully!");
        setEmail("");
        closemodal();
      })
      .catch((err) => {
        console.error("Failed to send email.", err);
        toast.dismiss();
        toast.warn("Failed to share the link. Please try again.");
      });
  };

  useEffect(() => {
    if (isVideoEnded) {
      setIsPlaying2(false);
    } else {
      setIsPlaying2(true);
    }
  }, [isVideoEnded]);

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="Full_Table">
                <TableHeader title="All Deals" showFilters={true} onSearch={handleSearch} onFilterChange={handleFilterChange} />
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Deal #</th>
                      <th scope="col">Customer name</th>
                      <th scope="col">Vin Number</th>
                      <th scope="col">Vehicle</th>
                      <th scope="col">Vehicle Color</th>
                      <th scope="col">Last updated</th>
                      <th scope="col">Phone number</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="dataTable">
                    {loading ? (
                      <div className="loader_Section">
                        <Loader />
                      </div>
                    ) : filteredData.length === 0 ? (
                      <div className="No-data-Vector">
                        <img src="./assets/Gif/NoDeal-Data.gif" alt="No Data" />
                      </div>
                    ) : (
                      paginatedData.map((deal) => (
                        <tr key={deal.id}>
                          <td>{deal.id}</td>
                          <td>
                            {deal.firstName} {deal.lastName}
                          </td>
                          <td>{deal.vinNumber}</td>
                          <td>{deal.vehicle}</td>
                          <td>{deal.vehicleColor}</td>
                          <td>{formatDate(deal.updatedAt)}</td>
                          <td>{deal.phoneNumber}</td>
                          <td>
                            <div className="Table_Actions" onClick={playClickSound}>
                              <a
                                href="/"
                                data-bs-toggle="modal"
                                data-bs-target="#WaitingModal"
                                onClick={() => {
                                  playClickSound();
                                  setSelectedDealId(deal.id);
                                  setselectedState(deal.state_name);
                                }}
                              >
                                <MdRemoveRedEye className="MdRemoveRedEye" />
                              </a>
                              <a href="/" data-bs-toggle="modal" data-bs-target="#shareLinkModal">
                                <FaShareAlt
                                  className="HiShare"
                                  onClick={() => {
                                    playClickSound();
                                    setShareDealId(deal.id);
                                  }}
                                />
                              </a>
                              <a
                                href="/"
                                data-bs-toggle="modal"
                                data-bs-target="#Delete-Deal-Confirmation"
                                onClick={() => {
                                  playClickSound();
                                  setDealToDelete(deal.id);
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" className="RiDeleteBin2Fill">
                                  <g clipPath="url(#clip0_141_11740)">
                                    <path
                                      d="M18.3333 3.33333H14.9158C14.5283 1.43417 12.8458 0 10.8333 0H9.16658C7.15492 0 5.47158 1.43417 5.08408 3.33333H1.66659C1.20659 3.33333 0.833252 3.70583 0.833252 4.16667C0.833252 4.6275 1.20659 5 1.66659 5H2.38325L3.46325 16.2325C3.66992 18.3808 5.45325 20 7.61075 20H12.3833C14.5441 20 16.3274 18.3775 16.5316 16.2267L17.5949 5H18.3324C18.7924 5 19.1658 4.6275 19.1658 4.16667C19.1658 3.70583 18.7933 3.33333 18.3333 3.33333ZM9.16658 1.66667H10.8333C11.9183 1.66667 12.8349 2.36583 13.1799 3.33333H6.82075C7.16575 2.36583 8.08242 1.66667 9.16742 1.66667H9.16658ZM13.0891 13.5775C13.4149 13.9033 13.4149 14.43 13.0891 14.7558C12.9266 14.9183 12.7133 15 12.4999 15C12.2866 15 12.0733 14.9183 11.9108 14.7558L9.99992 12.845L8.08908 14.7558C7.92658 14.9183 7.71325 15 7.49992 15C7.28658 15 7.07325 14.9183 6.91075 14.7558C6.58492 14.43 6.58492 13.9033 6.91075 13.5775L8.82158 11.6667L6.91075 9.75583C6.58492 9.43 6.58492 8.90333 6.91075 8.5775C7.23658 8.25167 7.76325 8.25167 8.08908 8.5775L9.99992 10.4883L11.9108 8.5775C12.2366 8.25167 12.7633 8.25167 13.0891 8.5775C13.4149 8.90333 13.4149 9.43 13.0891 9.75583L11.1783 11.6667L13.0891 13.5775Z"
                                      fill="#E15454"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_141_11740">
                                      <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                <div className="Table_foot justify-content-between">
                  <Stack spacing={2}>
                    <Pagination count={Math.ceil(filteredData.length / rowsPerPage)} page={currentPage} onChange={handlePageChange} shape="rounded" />
                  </Stack>

                  <div className="Foot_btn">
                    <MyButton
                      title="Add new deal"
                      data-bs-toggle="modal"
                      data-bs-target="#AddDealModal"
                      onModalOpen={() => {
                        setModalState(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal modalState={isModalOpen} />
      </section>

      {/*========== here is start shareLinkModal ========== */}

      <div className="modal" id="shareLinkModal" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog Waiting_pop">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <div className="d-flex align-items-center gap-2">
                <FaShareAlt className="HiShare FaShareAlt" />

                <h1 className="modal-title" id="exampleModalLabel">
                  Share Deal Link
                </h1>
              </div>
              <button type="button" className="btn-close" id="mailmodal" data-bs-dismiss="modal" aria-label="Close" onClick={closemodal}></button>
            </div>
            <div className="modal-body px-4">
              <InputFiled InputBoxClassname="half" InputType="email" InputName="EmailAddress" lableValue="Email Address" Inputplaceholder="name@example.com" onChange={(e) => setEmail(e.target.value)} value={email} />
            </div>
            <div className="modal-footer border-0 justify-content-center my-3">
              <button onClick={sendEmail} className="MyBtn">
                Share Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ===== here is start WaitingModal ======= */}

      <div className="modal" id="WaitingModal" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" aria-hidden="true">
        <div className="modal-dialog Waiting_pop rounded-3 p-0">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <div className="d-flex align-items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 18 19" fill="none" className="Waiting-Period-icon">
                  <g clipPath="url(#clip0_2394_613)">
                    <path d="M9 0.5C4.03725 0.5 0 4.53725 0 9.5C0 14.4628 4.03725 18.5 9 18.5C13.9628 18.5 18 14.4628 18 9.5C18 4.53725 13.9628 0.5 9 0.5ZM12 10.25H9C8.586 10.25 8.25 9.91475 8.25 9.5V5C8.25 4.58525 8.586 4.25 9 4.25C9.414 4.25 9.75 4.58525 9.75 5V8.75H12C12.4148 8.75 12.75 9.08525 12.75 9.5C12.75 9.91475 12.4148 10.25 12 10.25Z" fill="#2E302E"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_2394_613">
                      <rect width="18" height="18" fill="white" transform="translate(0 0.5)"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <h1 className="modal-title" id="exampleModalLabel">
                  Waiting Period
                </h1>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={playClickSound}></button>
            </div>
            <div className="modal-body px-4">
              <SelectField
                required
                className={half}
                InputName="Waiting Period"
                Selectlabel="Waiting Period"
                onChange={handleSelectChange}
                options={[
                  { value: "10", label: "10 Minutes" },
                  { value: "20", label: "20 Minutes" },
                  { value: "30", label: "30 Minutes" },
                  { value: "40", label: "40 Minutes" },
                  { value: "50", label: "50 Minutes" },
                  { value: "60", label: "1 Hour" },
                ]}
              />
            </div>
            <div className="modal-footer border-0 justify-content-center my-3">
              <span onClick={handleClick}>
                <MyButton title="Ok" data-bs-toggle="modal" data-bs-target="#DealVideoModal" />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start AddModal ========== */}

      <div className="modal" id="DealVideoModal" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog Waiting_pop bg-transparent">
          <div className="modal-content border-0 bg-transparent">
            <div className="DetailVerification_Versa_Bottons">
              {isPlaying2 ? (
                <button onClick={pauseVideo}>
                  <FaPause />
                </button>
              ) : (
                <button onClick={playVideo}>
                  <FaPlay />
                </button>
              )}
              <button onClick={replayVideo}>
                <MdOutlineReplay />
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="background-video position-static">
                <video className="w-100 Double_Videos" id="myVideo" ref={videoRef}></video>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center pb-0">
              <span onClick={handleNavigate}>
                <MyButton title="Continue" className="Secondry_Btn" data-bs-dismiss="modal" aria-label="Close" />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start delete deal confirmation popup ========== */}

      <div className="modal" id="Delete-Deal-Confirmation" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog DashboardModals rounded-3">
          <div className="modal-content border-0">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={playClickSound}></button>
            <div className="modal-body p-0 text-center">
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 45 46" fill="none">
                  <g clipPath="url(#clip0_347_3959)">
                    <path d="M44.3399 33.9417L27.1066 4.93834C26.2449 3.47167 24.7233 2.59167 22.9999 2.59167C21.2766 2.59167 19.7549 3.47167 18.9116 4.93834L1.65992 33.9417C0.798255 35.445 0.798255 37.2233 1.65992 38.7083C2.52159 40.1933 4.06159 41.0917 5.76659 41.0917H40.2516C41.9749 41.0917 43.5149 40.1933 44.3583 38.7083C45.2016 37.2233 45.2016 35.445 44.3399 33.9417ZM24.8333 33.7583H21.1666V30.0917H24.8333V33.7583ZM24.8333 26.425H21.1666V15.425H24.8333V26.425Z" fill="#E0C22D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_347_3959">
                      <rect width="45" height="45" fill="white" transform="translate(0 0.758301)" />
                    </clipPath>
                  </defs>
                </svg>
                <h4>Are you sure you want to delete this deal?</h4>
              </>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton className="Secondry_Btn" title="No" data-bs-dismiss="modal" aria-label="Close" />
              <button className="MyBtn" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleDelete(dealToDelete)}>
                {" "}
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deals;
